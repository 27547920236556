import { PublicKey } from "@solana/web3.js";
import { config } from "../config"
import { BN } from "@project-serum/anchor";
// export const PROGRAM_ID = config.isMainnet ?
//   new PublicKey(
//     "7K7WmndYPmKS4fALPhFwnf7yGm7GFJqQ997PzvVirSbT"
//   ) : new PublicKey(
//     "5VVx9LyoU7bvgiBiBb1WuwjxMZg2r2jotGSvfUzN8TbT"
//   );

  export const PROGRAM_ID = new PublicKey(
    "5j2Mh13vdUEiTPSLL2gPms2byTH8ktgovxrNJbAWNYhj"
  )
  export const PRESALE_AUTHORITY = new PublicKey(
    "78E7iNoy9LW8j4yP889w7ptHiF7p2penMejScPfGKGP3"
  )

  // export const PRESALE_AUTHORITY = config.isMainnet ?
  // new PublicKey(
  //   "78E7iNoy9LW8j4yP889w7ptHiF7p2penMejScPfGKGP3"
  // ) : new PublicKey(
  //   "Di6rR6Vkg7FN9Z6ioVeniLLtKSVU8JFVrC2JBeisRJWE"
  // );


// export const PRESALE_AUTHORITY = new PublicKey(
//   "78E7iNoy9LW8j4yP889w7ptHiF7p2penMejScPfGKGP3"
// );
// export const PRESALE_AUTHORITY = new PublicKey(
//   "2gM417ByVZN6JpBvbkpvChXC5YLYALxdvBg5JtQmxBPn"
// );
// export const PRESALE_AUTHORITY = new PublicKey(
//   "GnWh282jQQzdvKPXADT2atPEz2FGmGZXjepMc2CwAc2K"
// );


export const PRESALE_PROGRAM_PUBKEY = new PublicKey(
  "5j2Mh13vdUEiTPSLL2gPms2byTH8ktgovxrNJbAWNYhj"
);

// export const PRESALE_PROGRAM_PUBKEY = config.isMainnet ?
// new PublicKey(
//   "7K7WmndYPmKS4fALPhFwnf7yGm7GFJqQ997PzvVirSbT"
// ) : new PublicKey(
//   "5VVx9LyoU7bvgiBiBb1WuwjxMZg2r2jotGSvfUzN8TbT"
// );

export const TOKEN_PUBKEY = new PublicKey(
  "4ZfitzW524FUvWrGuNTg3x7d7cfCuNZzUaNEiB2BT5Lj"
);
// export const TOKEN_PUBKEY = config.isMainnet ?
// new PublicKey(
//   "4ZfitzW524FUvWrGuNTg3x7d7cfCuNZzUaNEiB2BT5Lj"
// ) : new PublicKey(
//   "8h4SSM7opsSm7VMDcxKSrJSLq3J9oE7HCMZyGRJEErP6"
// );

export const GLOBAL_STATE_SEED = "GLOBAL-STATE-SEED";
export const PRESALE_STATE_SEED = "PRESALE-STATE-SEED";
export const USER_STATE_SEED = "USER-STATE-SEED";
export const VAULT_STATE_SEED = "VAULT-STATE-SEED";
export const HUNDRED = 5000000;
export const THOUSAND = 10000000;

export const START_TIME = "2024-05-15T05:00:00-04:00";
export const DEAD_TIME = "2024-06-03T14:00-04:00";
// export const END_TIME = "2024-06-03T06:45-04:00";
export const END_TIME = "2024-11-26T23:00:00-04:00";

export const PRESALE_ID = 0;


export const TOKEN_PRESALE_HARDCAP = 10000000; // token
export const PRICE_PER_TOKEN = 0.025; // $
export const PRICE_DECIMAL = 9

export const BUYER_SOFTCAP = 0.2; // sol
export const BUYER_HARDCAP = 50; // sol
export const BUYER_TOKEN_HARDCAP = 50000000; // token

export const TOKEN_DECIMAL = 9;
export const USDT_DECIMAL = 6;


export const SOL_TOKEN_PUBKEY = new PublicKey("So11111111111111111111111111111111111111112")

export const USDC_TOKEN_PUBKEY = new PublicKey(
  "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"
);
// export const USDC_TOKEN_PUBKEY = config.isMainnet ?
// new PublicKey(
//   "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"
// ) : new PublicKey(
//   "C8qZTUNirrnsAW1TgVcmaPkqMVMgTGuZ1DBexuJ8xN7U"
// );
export const USDT_TOKEN_PUBKEY = new PublicKey(
  "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"
);
// export const USDT_TOKEN_PUBKEY = config.isMainnet ?
// new PublicKey(
//   "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"
// ) : new PublicKey(
//   "C8qZTUNirrnsAW1TgVcmaPkqMVMgTGuZ1DBexuJ8xN7U"
// );

//dev network test token address
// export const USDC_TOKEN_PUBKEY = new PublicKey('8zsxhLarg72J5H16F4jSbf4z5NisGJgCq6JJRSsPC3GF')
// export const USDT_TOKEN_PUBKEY = new PublicKey('8zsxhLarg72J5H16F4jSbf4z5NisGJgCq6JJRSsPC3GF')


// export const USDC_TOKEN_PUBKEY = new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v')
// export const USDT_TOKEN_PUBKEY = new PublicKey('Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB')
export const JUP_TOKEN_PUBKEY = new PublicKey('JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN')

export const SOL_PRICEFEED_ID = new PublicKey('H6ARHf6YXhGYeQfUzQNGk6rDNnLBQKrenN712K4AQJEG')
export const JUP_PRICEFEED_ID = new PublicKey('g6eRCbboSwK4tSWngn773RCMexr1APQr4uA9bGZBYfo')